export var mixin = {
  methods:{
    m_updateCoupon(coupon){
      if (coupon) {
        let items = [];
        this.$store.state.eCommerce.cartItems.forEach(function (item) {
          items.push({product_id: item.id, options: item.selected_options, qty: item.quantity});
        });
        this.axios.post('/coupon/apply', {
          "payment_method": "cod",
          "shipping_method": this.$store.state.user.shipping? this.$store.state.user.shipping.type:"",
          "terms_and_conditions": "on",
          coupon: coupon,
          item: items
        }).then(res => {
          if (res.status==200){
            if (res.data.status == 'error'){
              this.clearCoupon();
              this.$vs.notify({
                title: 'Error',
                text: res.data.message,
                color: 'danger'
              })
            }else{
              this.$store.commit('eCommerce/UPDATE_COUPON', res.data)
              this.$vs.notify({
                title: 'Coupon Updated',
                text: '',
                color: 'warning'
              })
            }
          }
        })
      }else {
        this.m_clearCoupon();
      }
    },
    m_clearCoupon(){
      this.coupon = '';
      this.$store.commit('eCommerce/UPDATE_COUPON', [])
    },
    m_pixel_AddToCart(data){
      window.fbq('track', 'AddToCart', data);
    },
    m_pixel_AddToWishlist(data){
      window.fbq('track', 'AddToWishlist', data);
    },
    m_pixel_QuickOrder(data){
      window.fbq('trackCustom', 'QuickOrder', data);
    },
    m_pixel_Search(data){
      window.fbq('track', 'Search', data);
    },
    m_pixel_Purchase(data){
      window.fbq('track', 'Purchase',data);
    }
  }
};
