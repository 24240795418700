/*=========================================================================================
  File Name: moduleEcommerceMutations.js
  Description: Ecommerce Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  TOGGLE_ITEM_IN_WISH_LIST(state, item) {
    const index = state.wishList.findIndex(i => i.id == item.id)
    if (index < 0) {
      state.wishList.push(item)
    } else {
      state.wishList.splice(index, 1)
    }
    localStorage.wish_list=JSON.stringify(state.wishList);
  },
  REMOVE_ITEM_FROM_CART(state, item) {
    const index = state.cartItems.findIndex(i => i.id == item.id)
    if (index > -1) { state.cartItems.splice(index, 1) }
    localStorage.cart_items=JSON.stringify(state.cartItems);
  },
  ADD_ITEM_IN_CART(state, item) {
    state.cartItems.push(Object.assign({}, item))
    localStorage.cart_items=JSON.stringify(state.cartItems);
  },
  UPDATE_ITEM_QUANTITY(state, payload) {
    state.cartItems[payload.index].quantity = payload.quantity
    localStorage.cart_items=JSON.stringify(state.cartItems);
  },
  UPDATE_COUPON(state, item) {
    state.coupon=item;
    localStorage.coupon=JSON.stringify(item);
  },
  CLEAR_CART(state){
    state.cartItems=[]
    localStorage.cart_items=JSON.stringify(state.cartItems);
  }
}

