/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,axios)
axios.defaults.baseURL = process.env.VUE_APP_URL+'/api/v1';
import Viewer from 'v-viewer'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 888888
  }
})
var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);
// Theme Configurations
import '../themeConfig.js'

// Vuesax Admin Filters
import './filters/filters'

// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import {mixin} from './mixins/mixin'

// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false
Vue.mixin(mixin);
import VueAuth from '@websanova/vue-auth'


import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
Vue.router  = router;
Vue.use(VueAuth, {
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js')
});



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
