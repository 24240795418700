/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////


// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
    theme                   : themeConfig.theme || "light",
    themePrimaryColor       : colors.primary,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
    baseUrl:process.env.VUE_APP_URL,
    pwa:process.env.VUE_APP_PWA,
    products : JSON.parse(localStorage.getItem('ddfehreusdfiu38jsjueruwoiwuwyu')) || [],
    categoryNested:JSON.parse(localStorage.getItem('ddfehreusdfiu38jsjueruwoiwuw')) || [],
    attributesFilterable:JSON.parse(localStorage.getItem('WERETRTs_ssdfiu38jsjueruwoiwuw_sdiiw')) || [],
    user: JSON.parse(localStorage.getItem('TRwu?ws_ssdfiu38jsjueruwoiwuw_sdiiw')) || {phone:'', name:'', address:'', shipping:''},
    settings:JSON.parse(localStorage.getItem('settings'))||[],
    categories:JSON.parse(localStorage.getItem('categories')) || [],
}

export default state
