/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
              {
                path: '/',
                name: 'home',
                component: () => import('./views/Home.vue')
              },
              {
                path: '/products',
                name: 'products',
                component: () => import('./views/Products.vue')
              },
              {
                path: '/products/:slug',
                name: 'product_view',
                component: () => import('./views/Product.vue')
              },
              {
                path: '/checkout',
                name: 'checkout',
                meta:{
                  auth:true,
                },
                component: () => import('./views/Checkout.vue')
              },
              {
                path: '/orders',
                name: 'orders',
                meta:{
                  auth:true,
                },
                component: () => import('./views/Orders.vue')
              },
              {
                path: '/view-order/:id',
                name: 'view_order',
                meta:{
                  auth:true,
                },
                component: () => import('./views/ViewOrder.vue')
              },
              {
                path: '/wish-list',
                name: 'wishList',
                component: () => import('./views/WishList.vue')
              },
              {
                path: '/flash-sales',
                name: 'FlashSale',
                component: () => import('./views/FlashSale.vue')
              },
              {
                path: '/categories',
                name: 'Categories',
                component: () => import('./views/Categories.vue')
              },
              {
                path: '/page/:id/:slug',
                name: 'Page',
                component: () => import('./views/components/Page.vue')
              },
            ],
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
              {
                path: '/login',
                name: 'login',
                component: () => import('@/views/pages/Login.vue')
              },
              {
                path: '/404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue')
              },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
    document.title = 'Himu - Ecommerce '
})

export default router
