/*=========================================================================================
  File Name: moduleEcommerceState.js
  Description: Ecommerce Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    cartItems: localStorage.cart_items? JSON.parse(localStorage.cart_items):[],
    wishList: localStorage.wish_list? JSON.parse(localStorage.wish_list):[],
    coupon:localStorage.coupon? JSON.parse(localStorage.coupon):[],
}
